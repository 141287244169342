import React from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.css';
import { IoArrowBackOutline } from 'react-icons/io5';

type DownloadModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

const DownloadModal: React.FC<DownloadModalProps> = ({isOpen, onClose}) => {
    if(!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={styles.background}>
            <div className={styles.modal}>
                <div className={styles.logo}>
                    <div className={styles.logoCircle}></div>
                </div>

                <div>
                    <span className={styles.title}>Descargar aplicación</span>
                    <p className={styles.information}>
                        Debido a la cuota monetaria de la Play Store para crear un cuenta, no pude
                        subir esta aplicación a la ya mencionada, sin embargo, puedes bajar la aplicación
                        por tu cuenta o bien, ver el video explicativo.
                    </p>
                </div>

                <div onClick={onClose} style={{cursor: "pointer"}}>
                    <a href={"https://github.com/Glazzes/rn-spring-qr-login/raw/main/app-release.apk"} className={styles.button}>
                        Descargar aplicación
                    </a>
                    <div className={styles.linkContainer}>
                        <IoArrowBackOutline color={"#065ad8"} size={20} />
                        <span className={styles.closeDialog}>Cerrar este diálogo</span>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById("portal")!!
    );
}

export default DownloadModal;
